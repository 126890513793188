$background-color: #e2ffff;

.light {
  .graphin-components-toolbar,
  .graphin-components-tooltip {
    background: $background-color !important;
    border: 1px solid #f9f9f9 !important;
  }
  .drawer-content-wrapper {
    background: $background-color !important;
  }
  .ant-layout {
    background: $background-color;
  }
  .ant-layout-content{
    background: $background-color;
  }
  .search-kit {
    background: $background-color;
    .ant-tabs-nav {
      background: $background-color;
    }
    .sk-layout__top-bar {
      background: $background-color;
    }
    .sk-panel {
      border-bottom: 1px solid #f0f0f0;
    }
  }
  table,
  .table {
    color: #212529;
    tr.selected-row {
      background: #eee;
    }
    th,
    td {
      border-top: 1px solid #dee2e6;
    }
    th {
      border-bottom: 2px solid #dee2e6;
    }
  }
  .dictionary-editable-desc[contenteditable="true"]:empty:before {
    color: #aaa !important;
  }
  .graphin-core {
    background-color: $background-color !important;
  }
  .quality-chart, .usage-chart {
    text {
      fill: #222;
    }
  }
  .vx-group.vx-axis-tick .vx-line {
    stroke: #222;
  }
  .vx-line.vx-axis-line
  {
    stroke: #222;
  }
  .vx-axis-label {
    display: none;
  }
  .runtime-node-detail-view-chart, .pod-usage-chart, .usage-chart {
    text {
      fill:#222222;
    }
  }
  .sticky-header {
    background: $background-color;
  }
}
    