$background-color: #001010;
.dark {
  // #catalog_right_section {
  //   background: #111 !important;
  // }
  .graphin-components-toolbar,
  .graphin-components-tooltip {
    background: #111 !important;
    border: 1px solid #3a3a3a !important;
  }
  .drawer-content-wrapper {
    background: rgb(27, 27, 27) !important;
  }
  .ant-layout-content{
    background: $background-color;
  }
  .search-kit {
    background: $background-color;
    .ant-tabs-nav {
      background: $background-color;
    }
    .sk-layout__top-bar {
      background: $background-color;
    }
    .sk-panel {
      border-bottom: 1px solid #303030;
    }
  }
  table,
  .table {
    color: hsla(0, 0%, 100%, 0.85);
    background: #111;
    tr.selected-row {
      background: #222;
    }
    th,
    td {
      border-top: 1px solid #3a3a3a;
    }
    th {
      border-bottom: 2px solid #3a3a3a;
    }
  }
  .dictionary-editable-desc[contenteditable="true"]:empty:before {
    color: #999 !important;
  }
  .modal-content {
    background-color: #111;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .modal-header {
      border-bottom: 1px solid #333;
    }
  }
  .ant-select-selector {
    background-color: #111111 !important;
  }
  .graphin-core {
    background-color: $background-color !important;
  }
  .quality-chart,
  .usage-chart {
    text {
      fill: #fff;
    }
  }
  .vx-group.vx-axis-tick .vx-line {
    stroke: #fff;
  }
  .vx-line.vx-axis-line {
    stroke: #fff;
  }
  .vx-axis-label {
    display: none;
  }
  .ace-nord-dark {
    background-color: transparent;
  }
  .runtime-node-detail-view-chart, .pod-usage-chart, .usage-chart {
    text {
      fill:#999999;
    }
  }
  .sticky-header {
    background: $background-color;
  }
}
